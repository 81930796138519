@import "bcf.prb.min.css";
@import "bcf.min.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button:focus {
  outline: 0;
}

.menu-lower a,
.action-wrap {
  cursor: pointer;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

/*.ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui),*/
/*.ui.accordion:not(.styled) .title ~ .content:not(.ui) {*/
/*  paddingtop: 0 !important;*/
/*}*/

@media screen and (max-width: 640px) {
  .mobile-bg-image {
    background-position: -690px 0px;
  }
}
