@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @variants responsive {
    .bcf-h1 {
      color: #e85494;
      font-size: 28px;
      line-height: 32px;
      font-weight: bold;
    }
    .bcf-homepage-header {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    .bcf-homepage-header-sm {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .footer-space {
      height: calc(100% - 6rem);
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

ol p {
  margin-bottom: 0;
}
