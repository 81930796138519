@charset "UTF-8";
@font-face {
  font-family: Gibson;
  src: url(//db.onlinewebfonts.com/t/af83729ef342708b89deb4fbe42a865d.eot);
  src: url(//db.onlinewebfonts.com/t/af83729ef342708b89deb4fbe42a865d.eot#iefix) format("embedded-opentype"), url(//db.onlinewebfonts.com/t/af83729ef342708b89deb4fbe42a865d.woff2) format("woff2"), url(//db.onlinewebfonts.com/t/af83729ef342708b89deb4fbe42a865d.woff) format("woff"), url(//db.onlinewebfonts.com/t/af83729ef342708b89deb4fbe42a865d.ttf) format("truetype"), url(//db.onlinewebfonts.com/t/af83729ef342708b89deb4fbe42a865d.svg#Gibson) format("svg");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Gibson;
  src: url(//db.onlinewebfonts.com/t/ad9c21e32d5c60a721d11912ca6a9291.eot);
  src: url(//db.onlinewebfonts.com/t/ad9c21e32d5c60a721d11912ca6a9291.eot#iefix) format("embedded-opentype"), url(//db.onlinewebfonts.com/t/ad9c21e32d5c60a721d11912ca6a9291.woff2) format("woff2"), url(//db.onlinewebfonts.com/t/ad9c21e32d5c60a721d11912ca6a9291.woff) format("woff"), url(//db.onlinewebfonts.com/t/ad9c21e32d5c60a721d11912ca6a9291.ttf) format("truetype"), url(//db.onlinewebfonts.com/t/ad9c21e32d5c60a721d11912ca6a9291.svg#Gibson) format("svg");
  font-weight: 600;
  font-style: normal
}

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xxs: 0px;
  --breakpoint-xs: 480px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1280px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
:after,
:before {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xs {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width:480px) {
  .container,
  .container-xs {
    max-width: 460px
  }
}

@media (min-width:576px) {
  .container,
  .container-sm,
  .container-xs {
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container,
  .container-md,
  .container-sm,
  .container-xs {
    max-width: 720px
  }
}

@media (min-width:992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xs {
    max-width: 960px
  }
}

@media (min-width:1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xs {
    max-width: 1220px
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}

.close:hover {
  color: #000;
  text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .75
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0
}

a.close.disabled {
  pointer-events: none
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: .875rem;
  background-color: hsla(0, 0%, 100%, .85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
  opacity: 0;
  border-radius: .25rem
}

.toast:not(:last-child) {
  margin-bottom: .75rem
}

.toast.showing {
  opacity: 1
}

.toast.show {
  display: block;
  opacity: 1
}

.toast.hide {
  display: none
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: hsla(0, 0%, 100%, .85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.toast-body {
  padding: .75rem
}

.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-50px)
}

@media (prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
  margin: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width:576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem)
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem)
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }
  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: min-content
  }
  .modal-sm {
    max-width: 300px
  }
}

@media (min-width:992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px
  }
}

@media (min-width:1280px) {
  .modal-xl {
    max-width: 1140px
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0
}

.tooltip.show {
  opacity: .9
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem
}

.tooltip .arrow:before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow:before,
.bs-tooltip-top .arrow:before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow:before,
.bs-tooltip-right .arrow:before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
.bs-tooltip-bottom .arrow:before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem
}

* {
  box-sizing: border-box
}

.bg-cinderella {
  background-color: #fce1e5
}

.bg-illusion {
  background-color: #f5a1bb
}

.bg-cranberry {
  background-color: #e85494
}

body,
html {
  background-color: #fce1e5;
  font-weight: 400;
  font-size: 1rem;
  font-family: Gibson, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  color: #616366;
  word-wrap: break-word;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-feature-settings: "kern";
  font-feature-settings: "kern";
  margin: 0;
  -webkit-font-smoothing: antialiased
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 .5em;
  font-family: Gibson, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 700;
  line-height: 1.3
}

.h1,
h1 {
  color: #e85494;
  font-size: 28px;
  line-height: 32px;
  line-height: 2rem;
  padding: 0 0 30px;
  margin-bottom: 30px;
  border-bottom: 0
}

@media (min-width:768px) {
  .h1,
  h1 {
    font-size: 32px
  }
}

.h2,
h2 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 28px;
  line-height: 1.75rem;
  color: #f5a1bb
}

.h3,
h3 {
  font-size: 18px;
  font-size: 1.125rem;
  color: #e85494
}

.h3,
.h4,
h3,
h4 {
  line-height: 20px;
  line-height: 1.25rem
}

.h4,
h4 {
  font-size: 16px;
  font-size: 1rem
}

.h5,
h5 {
  font-size: 13px;
  font-size: .8125rem
}

.h5,
.h6,
h5,
h6 {
  line-height: 16px;
  line-height: 1rem
}

.h6,
h6 {
  font-size: 12px;
  font-size: .75rem
}

p {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility
}

p:last-child {
  margin: 0
}

a {
  font-family: Gibson, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  background-color: transparent;
  -webkit-transition: color .15s linear;
  transition: color .15s linear;
  color: #e85494
}

a,
a:hover {
  text-decoration: none
}

a:hover {
  color: #f5a1bb;
  outline: 0
}

.alert.alert-danger {
  color: #db6496 !important;
  border-color: #e85494;
  font-weight: 700;
  display: block;
  font-size: .95rem;
  line-height: 1.6;
  padding: .5rem;
  background-color: #fdf1f5
}

table.table {
  margin-top: 1.5rem;
  color: #616366;
  border: 1px solid #dee2e6
}

.container {
  max-width: 1280px
}

.container .content-wrap {
  margin-top: 2.5rem;
  background-color: #fff;
  box-shadow: 0 46px 58px -31px rgba(245, 154, 169, .4)
}

.container .content-wrap iframe#pymtProcess {
  min-height: 600px;
  border: none;
  width: 100%
}

.container .content-wrap .activity-table {
  border: 3px solid #e9e9e9;
  border-top-color: #e85494;
  border-radius: 0 0 .25rem .25rem
}

.container .content-wrap .activity-table .header h4 {
  padding: 1rem;
  color: #e85494;
  font-weight: 400
}

.container .content-wrap .activity-table .activity-body p {
  padding: 1rem
}

.container .content-wrap .activity-table .activity-body p:first-child {
  padding-top: 0
}

.container .content-wrap .content-transfer-wrap h2 {
  color: #e85494;
  font-size: 27px;
  margin-bottom: 1rem;
  line-height: 32px
}

.container .content-wrap .content-transfer-wrap h2.break {
  margin-top: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  border-top: 1px solid #f5a1bb
}

.container .content-wrap .content-transfer-wrap .bank-details h4 {
  color: #e85494;
  font-size: 1.2em
}

.container .content-wrap .content-transfer-wrap .bank-details h4 b {
  width: 200px;
  display: inline-block;
  text-rendering: optimizeLegibility
}

.container .content-wrap .content-transfer-wrap .bank-details h4 span {
  display: inline-block;
  text-rendering: optimizeLegibility;
  color: #434e58;
  font-weight: 400;
  font-family: arial;
  font-size: .9em
}

.container .content-wrap .content-profile-wrap .details-block {
  padding: 20px;
  background: #e9e9e9
}

.container .content-wrap .content-profile-wrap .social-media-share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option {
  display: block;
  padding: 0 1rem;
  border-bottom: none;
  font-weight: 700
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option:hover {
  border-bottom: none
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option:hover .share-icon {
  background-color: #e85494 !important
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option .share-icon {
  width: 64px;
  height: 64px;
  display: block;
  margin: 0 auto 1em;
  position: relative;
  border-radius: 50%;
  transition: .2s
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option .share-icon:before {
  width: 32px;
  height: 32px;
  position: absolute;
  content: " ";
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option.facebook .share-icon {
  background-color: #3b5998
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option.twitter .share-icon {
  background-color: #1da1f2
}

.container .content-wrap .content-profile-wrap .social-media-share .share-option.linkedin .share-icon {
  background-color: #0077b5
}

.container .content-wrap .content-donate-wrap h3 {
  margin-bottom: 15px;
  font-weight: 700
}

.container .content-wrap .content-donate-wrap h3 span {
  padding-top: .5rem;
  display: block;
  font-size: 15px
}

.container .content-wrap .content-donate-wrap .radio-group .inline-field {
  display: none;
  opacity: 0
}

.container .content-wrap .content-donate-wrap .amount-display .amount-display-value {
  color: #f5a1bb;
  float: none;
  font-size: 2.1em;
  font-weight: 700;
  vertical-align: middle;
  border-bottom: 1px dotted #f5a1bb;
  min-width: 150px
}

.container .content-wrap .content-donate-wrap .donate-form-wrap {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  display: block;
  opacity: 1
}

.container .content-wrap .content-donate-wrap .donate-form-wrap.default {
  display: none;
  opacity: 0
}

.container .content-wrap .content-donate-wrap .progress-wrap {
  border-top: 3px solid #e85494;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem
}

@media (min-width:768px) {
  .container .content-wrap .content-donate-wrap .progress-wrap {
    flex-direction: row
  }
  .container .content-wrap .content-donate-wrap .progress-wrap .step {
    flex: 1 1 33%
  }
}

.container .content-wrap .content-donate-wrap .progress-wrap .step {
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 400;
  color: rgba(245, 161, 187, .5);
  font-size: 15px
}

.container .content-wrap .content-donate-wrap .progress-wrap .step.in-progress .label {
  color: #e85494
}

.container .content-wrap .content-donate-wrap .progress-wrap .step.in-progress .tick {
  background-color: #e85494
}

.container .content-wrap .content-donate-wrap .progress-wrap .step .label {
  flex: 1 1 auto;
  padding: .5rem 1rem
}

.container .content-wrap .content-donate-wrap .progress-wrap .step .tick {
  color: #fff;
  flex: 0 0 auto;
  border: 0;
  overflow: hidden;
  width: 36px;
  height: 36px;
  border-color: transparent;
  border-style: solid;
  border-width: 8px 10px;
  background-color: #f8e0ea
}

.container .content-wrap .promote-block {
  padding: 3rem;
  text-align: center;
  margin-top: 2rem;
  background: 1px solid #e8e8e8;
  border-radius: .5rem;
  background-color: #f5f5f5
}

.container .content-wrap .promote-block h3 {
  font-weight: 700;
  color: #616366;
  font-size: 1.8rem
}

.container .content-wrap .promote-block h3 .step {
  background: #f5a1bb;
  color: #fff;
  border-radius: 20em;
  width: 2.4em;
  height: 2.4em;
  line-height: 2.4em;
  font-size: .5em;
  display: inline-block;
  margin: 0 .5em 0 0;
  vertical-align: middle
}

.container .content-wrap .promote-block .form-control {
  padding: 1rem
}

.container .content-wrap .promote-block .btn {
  margin: 0
}

.container .content-wrap .content-heading {
  background-color: #fdf1f5
}

.container .content-wrap .content-heading h1 {
  padding: 1em;
  margin: 0 auto;
  background-color: transparent;
  text-align: center;
  color: #e85494;
  font-size: 1.6em;
  display: block;
  max-width: 77rem;
  line-height: 1.1;
  letter-spacing: .05em;
  text-transform: uppercase
}

@media (min-width:576px) {
  .container .content-wrap .content-heading h1 {
    font-size: 2em
  }
}

.container .content-wrap .content-heading-sub {
  padding: 0 3rem 1.5rem;
  border-top: 1px solid #fce1e5;
  background-color: #fdf1f5
}

.container .content-wrap .content-menu-sub {
  padding: .75rem 3rem 0;
  border-top: 1px solid #fce1e5;
  background-color: #fdf1f5;
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center
}

.container .content-wrap .content-menu-sub .item-menu {
  flex: 0 0 auto;
  padding: 0 .5rem .5rem;
  margin: 0 .5rem;
  border-bottom: 4px solid transparent
}

.container .content-wrap .content-menu-sub .item-menu:hover {
  border-bottom: 4px solid #f5a1bb
}

.container .content-wrap .content-menu-sub .item-menu.active {
  border-bottom: 4px solid #e85494
}

.container .content-wrap .content-body-wrap {
  padding: 3rem 2rem
}

@media (min-width:768px) {
  .container .content-wrap .content-body-wrap {
    padding: 5rem 5rem 0
  }
}

.container .content-wrap .content-body-wrap:last-child {
  padding-bottom: 7rem
}

.container .content-wrap .content-body-wrap .text-break {
  margin: 3rem 0 2rem;
  border-bottom: 1px solid #f5a1bb
}

.container .content-wrap .content-body-wrap h2 {
  padding-bottom: 1.5rem
}

.container .content-wrap .content-body-wrap a:not(.btn) {
  border-bottom: 1px solid rgba(245, 161, 187, .3);
  color: #e85494;
  font-weight: 400
}

.container .content-wrap .content-body-wrap a:not(.btn):hover {
  color: #e85494;
  border-bottom-color: #e85494
}

.container .content-wrap .content-body-wrap a:not(.btn).img,
.container .content-wrap .content-body-wrap a:not(.btn).img:hover {
  border-bottom: none
}

.container .content-wrap .content-body-wrap p>img {
  height: 100%;
  width: 100%
}

.container .content-wrap .content-fundraiser-wrap .fundraiser-detail h2 {
  margin-top: 1rem
}

.container .content-wrap .content-fundraiser-wrap .fundraiser-detail .fundraiser-image-wrap img {
  width: 100%
}

.container .content-wrap .content-fundraiser-wrap .fundraiser-detail .stats {
  font-size: 18px
}

@media (min-width:768px) {
  .container .content-wrap .content-fundraiser-wrap .fundraiser-detail {
    padding-right: 3rem
  }
}

.container .content-wrap .image-wrap,
.container .content-wrap .image-wrap a,
.container .content-wrap .image-wrap img {
  width: 100%
}

.container .content-wrap .events-wrap h2 {
  margin-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #fce1e5
}

.container .content-wrap .events-wrap .events-list-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem
}

.container .content-wrap .events-wrap .events-list-wrap>* {
  flex: 0 0 50%;
  min-width: 0;
  padding: 1rem
}

@media (min-width:768px) {
  .container .content-wrap .events-wrap .events-list-wrap>* {
    flex: 0 0 33%
  }
}

.container .content-wrap .events-wrap .events-list-wrap .item {
  background-color: hsla(0, 0%, 91.4%, .5);
  height: 100%;
  display: flex;
  flex-direction: column
}

.container .content-wrap .events-wrap .events-list-wrap .item>* {
  flex: 0 0 auto;
  min-width: 0
}

.container .content-wrap .events-wrap .events-list-wrap .item .title {
  padding: 1rem;
  text-align: center
}

.container .content-wrap .events-wrap .events-list-wrap .item .title a {
  font-weight: 800
}

.container .content-wrap .events-wrap .events-list-wrap .item p {
  padding: 0 1rem 1rem
}

.container .content-wrap .fundraisers-wrap h2 {
  margin-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #fce1e5
}

.container .content-wrap .fundraisers-wrap .fundraisers-board {
  margin-bottom: 2rem
}

.container .content-wrap .donations-wrap h2 {
  margin-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #fce1e5
}

.container .content-wrap .donations-wrap .donations-list-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem
}

.container .content-wrap .donations-wrap .donations-list-wrap>* {
  flex: 0 0 50%;
  min-width: 0
}

@media (min-width:768px) {
  .container .content-wrap .donations-wrap .donations-list-wrap>* {
    flex: 0 0 33%
  }
}

@media (min-width:992px) {
  .container .content-wrap .donations-wrap .donations-list-wrap>* {
    flex: 0 0 20%
  }
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item {
  border: 3px solid hsla(0, 0%, 91.4%, .5);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item>* {
  flex: 0 0 auto;
  min-width: 0
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item .icon {
  padding-top: 1rem;
  text-align: center;
  background-color: #e85494;
  color: #fff
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item .amount {
  margin: 0 0 1rem;
  padding: 0 1rem 1rem;
  width: 100%;
  font-weight: 800;
  font-size: 28px;
  color: #fff;
  background-color: #e85494
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item .amount span i {
  color: #f5a1bb
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item .from {
  text-align: center;
  color: #e85494;
  font-size: 18px;
  padding: 0 1rem .5rem
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item .message {
  padding: 0 1rem;
  text-align: center;
  margin-bottom: 1rem;
  height: 48px;
  background-color: hsla(0, 0%, 100%, .6);
  white-space: nowrap;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

@supports (-webkit-line-clamp:2) {
  .container .content-wrap .donations-wrap .donations-list-wrap>* .item .message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
  }
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item .message:hover {
  overflow: visible;
  text-overflow: clip;
  height: auto;
  display: block;
  z-index: 999;
  width: 100%
}

@supports (-webkit-line-clamp:2) {
  .container .content-wrap .donations-wrap .donations-list-wrap>* .item .message:hover {
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
    display: block;
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical
  }
}

.container .content-wrap .donations-wrap .donations-list-wrap>* .item .date {
  padding-bottom: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  font-weight: 400;
  color: #f5a1bb;
  text-align: right
}

.container .content-wrap .search-form-wrap {
  text-align: center
}

.container .content-wrap .search-form-wrap form {
  max-width: 400px;
  margin: 0 auto
}

.container .content-wrap .board-results-wrap,
.container .content-wrap .search-results-wrap {
  min-height: 200px
}

.container .content-wrap .board-results-wrap h2,
.container .content-wrap .search-results-wrap h2 {
  text-align: center
}

.container .content-wrap .fundraisers-list {
  display: flex;
  flex-direction: column;
  align-items: stretch
}

.container .content-wrap .fundraisers-list>article {
  flex: 0 0 auto;
  min-width: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem
}

.container .content-wrap .fundraisers-list>article.no-results {
  flex: 1 1 100%;
  text-align: center
}

.container .content-wrap .fundraisers-list>article.no-results a {
  margin-top: 1.5rem;
  display: inline-block;
  border-bottom: none;
  color: #fff
}

.container .content-wrap .fundraisers-list>article.no-results a:hover {
  border-bottom: none
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel {
  padding: .5rem;
  background-color: hsla(0, 0%, 91.4%, .5);
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  min-width: 0
}

@media (min-width:768px) {
  .container .content-wrap .fundraisers-list>article .fundraiser-panel {
    flex-direction: row
  }
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel>* {
  flex: 0 0 auto
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel h3 {
  margin-top: 1rem;
  font-size: 1rem
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-total {
  padding-top: .75rem
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-owner {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-details-wrap {
  display: flex;
  flex-direction: column
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-details-wrap>* {
  flex: 0 0 auto;
  margin: 0
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-details-wrap .controls {
  display: flex;
  flex-direction: row
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-details-wrap .controls>* {
  display: block;
  margin-right: 2rem
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-count {
  font-size: 14px
}

@media (min-width:768px) {
  .container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-image-wrap {
    max-width: 300px;
    padding-right: 2rem
  }
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-image-wrap a,
.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-image-wrap a:hover {
  border-bottom: none
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-image-wrap a img {
  width: 100%;
  object-fit: cover
}

.container .content-wrap .fundraisers-list>article .fundraiser-panel .fundraiser-image-wrap.square a img {
  max-height: unset;
  height: 100%
}

.container .content-wrap .fundraisers-board {
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: stretch
}

.container .content-wrap .fundraisers-board>article {
  flex: 0 0 50%;
  padding: .5rem;
  min-width: 0
}

@media (min-width:576px) {
  .container .content-wrap .fundraisers-board>article {
    flex: 0 0 33%
  }
}

@media (min-width:768px) {
  .container .content-wrap .fundraisers-board>article {
    flex: 0 0 25%
  }
}

@media (min-width:992px) {
  .container .content-wrap .fundraisers-board>article {
    flex: 0 0 20%
  }
}

.container .content-wrap .fundraisers-board>article.no-results {
  flex: 1 1 100%;
  text-align: center
}

.container .content-wrap .fundraisers-board>article.no-results a {
  margin-top: 1.5rem;
  display: inline-block;
  border-bottom: none;
  color: #fff
}

.container .content-wrap .fundraisers-board>article.no-results a:hover {
  border-bottom: none
}

.container .content-wrap .fundraisers-board>article.loader {
  flex: 1 1 auto;
  text-align: center;
  min-height: 200px
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel {
  padding: .5rem;
  background-color: hsla(0, 0%, 91.4%, .5);
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel>* {
  flex: 0 0 auto
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel h3 {
  margin-top: 1rem;
  font-size: 1rem
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel .fundraiser-total {
  padding-top: .75rem
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel .fundraiser-count {
  font-size: 14px
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel .fundraiser-owner {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel .fundraiser-image-wrap a,
.container .content-wrap .fundraisers-board>article .fundraiser-panel .fundraiser-image-wrap a:hover {
  border-bottom: none
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel .fundraiser-image-wrap a img {
  width: 100%;
  max-height: 140px;
  object-fit: cover
}

.container .content-wrap .fundraisers-board>article .fundraiser-panel .fundraiser-image-wrap.square a img {
  max-height: unset;
  height: 100%
}

.container .content-wrap .pagination {
  margin: 2rem auto;
  list-style: none;
  display: flex;
  flex-direction: row;
  line-height: 1.6;
  font-size: 1rem;
  justify-items: center;
  align-items: center
}

.container .content-wrap .pagination li {
  flex: 0 0 auto
}

.container .content-wrap .pagination li a {
  display: block;
  padding: .25rem .75rem;
  margin: 0 .5rem;
  border-bottom: none !important;
  border-radius: 6px;
  color: #616366
}

.container .content-wrap .pagination li a:hover {
  color: #4d4f53;
  background-color: #ddd;
  border-bottom: none !important
}

.container .content-wrap .pagination li a.disabled {
  color: #999;
  cursor: default
}

.container .content-wrap .pagination li a.disabled:hover {
  background-color: transparent
}

.container .content-wrap .pagination li a.current {
  background-color: #e85494;
  color: #fff
}

.container .content-wrap .pagination li a.current:hover {
  background-color: #f5a1bb
}

#ui-datepicker-div {
  z-index: 1000 !important
}

header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 120px;
  width: 100%;
  position: relative;
  background: #fff;
  z-index: 300;
  box-shadow: 0 0 40px rgba(245, 154, 169, .4)
}

@media (min-width:768px) {
  header {
    margin-top: 55px
  }
}

header .header-wrap {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  align-content: flex-start
}

@media only screen and (min-width:1350px) {
  header .header-wrap {
    margin: 0 2.5rem
  }
}

header .header-wrap .logo-block {
  flex: 0 0 auto;
  height: 120px;
  padding: 1.25rem
}

header .header-wrap .logo-block a {
  display: block;
  height: 80px
}

header .header-wrap .logo-block a img {
  display: block;
  border: none;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto
}

header .header-wrap .menu-small {
  align-self: center;
  padding: 1rem
}

@media (min-width:576px) {
  header .header-wrap .menu-small {
    display: none
  }
}

header .header-wrap .menu-small a {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  margin-right: .25rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: #e85494;
  align-items: center
}

header .header-wrap .menu-small a:hover {
  color: #f5a1bb
}

header .header-wrap .menu-small a span {
  margin-right: .25rem
}

header .header-wrap .menu-block {
  display: none;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-end;
  justify-items: center;
  height: 100%;
  padding-top: 1rem
}

@media (min-width:576px) {
  header .header-wrap .menu-block {
    display: flex
  }
}

header .header-wrap .menu-block .menu-upper {
  display: flex;
  flex: 0 0 auto;
  padding-right: 1rem;
  min-height: 40px;
  max-height: 40px
}

header .header-wrap .menu-block .menu-upper>* {
  flex: 1 1 auto
}

header .header-wrap .menu-block .menu-upper .item {
  padding: .5rem .75rem;
  height: 100%
}

@media (min-width:768px) {
  header .header-wrap .menu-block .menu-upper .item {
    padding: .5rem 1.25rem
  }
}

header .header-wrap .menu-block .menu-upper .item.search {
  padding: 0 0 0 1.25rem;
  background: #edeeef;
  border: 1px solid #edeeef
}

@media (min-width:768px) {
  header .header-wrap .menu-block .menu-upper .item.search {
    padding-right: .5rem
  }
}

header .header-wrap .menu-block .menu-upper .item.search .search-form {
  display: flex;
  flex-direction: row
}

header .header-wrap .menu-block .menu-upper .item.search button {
  flex: 0 0 auto;
  background-color: transparent;
  border: 0;
  padding: .5rem;
  outline: none
}

header .header-wrap .menu-block .menu-upper .item.search button:focus {
  outline: none
}

header .header-wrap .menu-block .menu-upper .item.link {
  background-color: #f5a1bb
}

header .header-wrap .menu-block .menu-upper .item.link a {
  display: inline-block;
  max-height: 1.5rem;
  line-height: 1.5rem;
  color: #fff;
  font-size: .875rem
}

header .header-wrap .menu-block .menu-upper .item.link a:hover {
  color: #fce1e5
}

@media (min-width:768px) {
  header .header-wrap .menu-block .menu-upper .item.link span {
    padding-right: .5rem
  }
}

header .header-wrap .menu-block .menu-upper .item.link i.sprites {
  margin-top: -.25rem;
  display: none !important
}

@media (min-width:768px) {
  header .header-wrap .menu-block .menu-upper .item.link i.sprites {
    display: inline-block !important
  }
}

header .header-wrap .menu-block .menu-upper .item.link.primary {
  background-color: #e85494
}

header .header-wrap .menu-block .menu-upper .item.link.primary a:hover {
  color: #f5a1bb
}

header .header-wrap .menu-block .menu-upper .item.link.primary span+i {
  display: inline-block;
  padding-left: .5rem
}

header .header-wrap .menu-block .menu-lower {
  display: flex;
  height: auto;
  align-self: flex-end;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 550px;
  padding-right: .25rem
}

@media (min-width:800px) {
  header .header-wrap .menu-block .menu-lower {
    max-width: 100%;
    padding-right: .25rem;
    padding-bottom: 1rem
  }
}

header .header-wrap .menu-block .menu-lower .item {
  margin: 0;
  padding: 0
}

header .header-wrap .menu-block .menu-lower .item a {
  display: block;
  padding: .5rem .75rem;
  color: #4d4f53;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: right
}

header .header-wrap .menu-block .menu-lower .item a:hover {
  color: #e85494
}

@media (min-width:800px) {
  header .header-wrap .menu-block .menu-lower .item a {
    padding: 1.25rem .75rem .75rem
  }
}

header .header-wrap .menu-block .menu-lower .item.dropdown {
  position: relative;
  transition: .15s ease-in-out
}

header .header-wrap .menu-block .menu-lower .item.dropdown:hover>a {
  color: #e85494
}

header .header-wrap .menu-block .menu-lower .item.dropdown:hover>a:after {
  content: "\f0d8"
}

header .header-wrap .menu-block .menu-lower .item.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1
}

header .header-wrap .menu-block .menu-lower .item.dropdown>a {
  transition: .15s ease-in-out
}

header .header-wrap .menu-block .menu-lower .item.dropdown>a:after {
  display: inline-block;
  padding-left: 10px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  content: "\f0d7"
}

header .header-wrap .menu-block .menu-lower .item.dropdown .dropdown-content {
  transition: all .15s ease-in-out;
  list-style: none;
  padding: 10px;
  margin: 0 0 0 12px;
  position: absolute;
  display: none;
  opacity: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: .875rem;
  height: auto;
  max-height: none;
  width: 100%;
  min-width: 230px
}

header .header-wrap .menu-block .menu-lower .item.dropdown .dropdown-content li:hover {
  background: #eee
}

header .header-wrap .menu-block .menu-lower .item.dropdown .dropdown-content li a {
  text-align: left
}

header .header-wrap .menu-block .menu-lower .item.dropdown .dropdown-content:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border: 6px inset transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  top: -12px;
  left: 10px;
  z-index: 89
}

header .header-wrap .menu-block .menu-lower .item.dropdown .dropdown-content:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border: 7px inset transparent;
  border-bottom: 7px solid #ccc;
  position: absolute;
  top: -14px;
  left: 9px;
  z-index: 88
}

header.expand {
  height: 160px
}

body.menu-small-visible .site-nav-slide-out {
  display: flex;
  z-index: 250
}

body.menu-small-visible .overlay {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%
}

@media (min-width:576px) {
  body.menu-small-visible {
    overflow-y: auto
  }
  body.menu-small-visible .overlay,
  body.menu-small-visible .site-nav-slide-out {
    display: none
  }
}

body .overlay {
  background: rgba(0, 0, 0, .4);
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 200
}

body .overlay,
body .site-nav-slide-out {
  display: none;
  width: 100%;
  min-height: 100%;
  top: 0
}

body .site-nav-slide-out {
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  z-index: 99;
  padding-top: 176px
}

>body .site-nav-slide-out {
  flex: 0 0 auto
}

body .site-nav-slide-out .site-nav-list {
  border-top: 1px solid #ddd;
  list-style: none;
  margin: 0;
  padding: 20px
}

body .site-nav-slide-out .site-nav-list .item a {
  display: block;
  padding: .75rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: #4d4f53
}

body .site-nav-slide-out .site-nav-list .item a:hover {
  color: #e85494
}

body .site-nav-slide-out .site-nav-list .item.separator {
  border-top: 1px solid #f5a1bb
}

body .site-nav-slide-out .site-nav-tools {
  border-top: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px
}

body .site-nav-slide-out .site-nav-tools .search {
  padding: 0 1.25rem;
  background: #edeeef;
  border: 1px solid #edeeef
}

body .site-nav-slide-out .site-nav-tools .search form {
  display: flex;
  flex-direction: row
}

body .site-nav-slide-out .site-nav-tools .search button {
  flex: 0 0 auto;
  background-color: transparent;
  border: 0;
  padding: .5rem;
  outline: none
}

body .site-nav-slide-out .site-nav-tools .search button:focus {
  outline: none
}

body .site-nav-slide-out .site-nav-tools .links {
  padding-top: 20px;
  display: flex;
  flex-direction: row
}

body .site-nav-slide-out .site-nav-tools .links>* {
  flex: 0 0 auto
}

body .site-nav-slide-out .site-nav-tools .links .link {
  padding: .5rem 1rem;
  height: 100%;
  background-color: #f5a1bb
}

body .site-nav-slide-out .site-nav-tools .links .link a {
  display: inline-block;
  max-height: 1.5rem;
  line-height: 1.5rem;
  color: #fff;
  font-size: .875rem
}

body .site-nav-slide-out .site-nav-tools .links .link a:hover {
  color: #fce1e5
}

body .site-nav-slide-out .site-nav-tools .links .link span {
  padding-right: .5rem
}

body .site-nav-slide-out .site-nav-tools .links .link i.sprites {
  margin-top: -.25rem
}

body .site-nav-slide-out .site-nav-tools .links .link.primary {
  background-color: #e85494
}

body .site-nav-slide-out .site-nav-tools .links .link.primary a:hover {
  color: #f5a1bb
}

body .site-nav-slide-out .site-nav-tools .links .link.primary span+i {
  display: inline-block;
  padding-left: .5rem
}

footer {
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap
}

@media (min-width:768px) {
  footer {
    flex-direction: row;
    padding: 5 1rem
  }
}

footer>* {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap
}

footer .links {
  flex-direction: column;
  padding-bottom: 1.5rem
}

@media (min-width:768px) {
  footer .links {
    flex-direction: row;
    padding-bottom: 0
  }
}

footer .links .link {
  border: 0
}

@media (min-width:768px) {
  footer .links .link {
    border-right: 1px solid #e85494
  }
  footer .links .link:last-child {
    border-right: 0
  }
}

footer .links .link a {
  padding: .75rem 1.25rem;
  margin: -.75rem 0;
  font-size: .8125rem;
  line-height: 1rem;
  display: inline-block;
  color: #e85494
}

footer .links .link a:hover {
  color: #f5a1bb
}

footer .copyright {
  color: #f5a1bb;
  font-size: .8125rem
}

footer .copyright>* {
  display: inline-block;
  margin-left: .5rem;
  text-align: center
}

@media (min-width:768px) {
  footer .copyright>* {
    text-align: right
  }
}

footer .copyright a {
  border-bottom: 1px solid rgba(245, 161, 187, .3);
  color: #f5a1bb;
  white-space: break-spaces
}

@media (min-width:992px) {
  footer .copyright a {
    white-space: nowrap
  }
}

footer .copyright a:hover {
  color: #e85494;
  border-bottom-color: #e85494
}

.spacer {
  flex: 1 1 auto !important
}

nav.common {
  position: relative;
  display: flex;
  flex-direction: row;
  background: #4d4f53;
  z-index: 500;
  left: 0;
  top: 0;
  height: 55px;
  overflow: hidden;
  -webkit-transition: .4s cubic-bezier(.86, 0, .07, 1);
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

@media (min-width:768px) {
  nav.common {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%
  }
}

nav.common>* {
  flex: 0 0 auto;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap
}

nav.common .links,
nav.common .properties,
nav.common .social-media {
  display: flex;
  flex-direction: row
}

nav.common .links>*,
nav.common .properties>*,
nav.common .social-media>* {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap
}

nav.common .property {
  margin: 0;
  padding: 0 1.5rem;
  border-left: 1px solid hsla(0, 0%, 100%, .1)
}

nav.common .property:first-child {
  border-left: 0
}

nav.common .property>a {
  opacity: .4;
  transition: .15s ease-in-out;
  font-size: .75em
}

nav.common .property>a.active,
nav.common .property>a:hover {
  opacity: 1
}

nav.common .social {
  margin: 0;
  padding: 0 .5rem
}

nav.common .social>a {
  opacity: 1
}

nav.common .social>a:hover {
  opacity: .8
}

nav.common .link {
  display: block;
  padding: 1.5em 2.2em;
  position: relative;
  text-transform: uppercase;
  color: #fff;
  font-size: .75rem;
  font-weight: 700;
  text-align: center
}

@media (min-width:768px) {
  nav.common .link {
    letter-spacing: .125rem
  }
}

nav.common .link.primary {
  background-color: #e85494;
  padding: 1.5em 2.2em
}

nav.common .link.primary i+span {
  display: none;
  padding-left: .5rem
}

@media (min-width:350px) {
  nav.common .link.primary i+span {
    display: inline-block
  }
}

nav.common .link>a {
  font-size: .75rem;
  font-weight: 700;
  color: #fff;
  opacity: 1
}

nav.common .link>a:hover {
  opacity: .8;
  color: #fce1e5
}

.sprites {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  height: 10px;
  width: 10px;
  background-image: url(sprites.png);
  background-repeat: no-repeat;
  font-size: .75em;
  color: transparent
}

@media not all,
only screen and (-webkit-min-device-pixel-ratio:2) {
  .sprites {
    background-size: 600px
  }
}

@media not all,
only screen and (-webkit-min-device-pixel-ratio:2) {
  .sprites.cranberry {
    background-size: 600px
  }
}

.sprites.logo-nzbcf {
  width: 87px;
  height: 41px;
  background-position: 0 7px
}

.sprites.logo-prb {
  width: 46px;
  height: 41px;
  background-position: -100px 4px
}

.sprites.logo-prw {
  width: 39px;
  background-position: -150px 0;
  height: 41px
}

.sprites.logo-ta {
  width: 50px;
  background-position: -500px -50px;
  height: 26px
}

.sprites.icon-facebook {
  width: 19px;
  height: 19px;
  background-position: -200px 0
}

.sprites.icon-twitter {
  width: 19px;
  height: 17px;
  background-position: -250px 0
}

.sprites.icon-instagram {
  width: 19px;
  height: 19px;
  background-position: -300px 0
}

.sprites.icon-youtube {
  width: 23px;
  height: 16px;
  background-position: -300px -50px
}

.sprites.icon-pinterest {
  width: 16px;
  height: 20px;
  background-position: -300px -100px
}

.sprites.icon-heart {
  background-position: -400px 0;
  width: 20px;
  height: 20px
}

.sprites.icon-search {
  background-position: -450px -200px;
  width: 20px;
  height: 20px
}

.sprites.icon-user {
  background-position: -450px -250px;
  width: 24px;
  height: 24px
}

.sprites.icon-flag {
  background-position: 0 -148px;
  width: 36px;
  height: 36px
}

.icon-fav.x1 {
  height: 36px;
  width: 36px
}

.icon-fav.x2 {
  height: 48px;
  width: 48px
}

.icon.icon-amountraised,
.icon.icon-hosts,
.icon.icon-walkers {
  max-width: 100px;
  max-height: 120px;
  margin: auto auto 1rem
}

.icon.icon-amountraised img,
.icon.icon-hosts img,
.icon.icon-walkers img {
  max-width: 100%;
  max-height: 100%
}

.icon.icon-hosts {
  max-width: 120px
}

.icon.icon-walkers {
  max-width: 95px
}

#instagram-feed {
  background-color: #fff
}

#instagram-feed .heading {
  margin: 0 0 .5em;
  padding: 0 2rem 2rem;
  font-family: Gibson, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 700;
  line-height: 1.3
}

#instagram-feed .heading:before {
  content: " ";
  width: 100px;
  height: 4px;
  background: #e85494;
  display: block;
  margin: 0 auto 3em
}

#instagram-feed .heading h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #e85494
}

@media (min-width:768px) {
  #instagram-feed .heading h2 {
    font-size: 2.3125rem
  }
}

#instagram-feed a.crt-logo.crt-tag {
  display: block;
  padding: .25rem;
  border-bottom: 0;
  background-color: #fff;
  color: #2b2b2b;
  font-size: .9em;
  text-align: center;
  left: unset;
  right: .3rem
}

#instagram-feed a.crt-logo.crt-tag:hover {
  color: #616366
}

#instagram-feed .crt-feed,
#instagram-feed .crt-widget {
  padding-bottom: 0
}

#instagram-feed .crt-load-more-container .crt-load-more {
  padding: 1rem 1rem 0;
  margin-bottom: 2rem;
  border-color: transparent;
  background-color: #fff;
  color: #2b2b2b;
  border-bottom: 1px solid rgba(240, 156, 175, .3);
  margin-top: 0
}

#instagram-feed .crt-load-more-container .crt-load-more:hover {
  color: #616366;
  border-bottom-color: #e85494
}

#instagram-feed .crt-load-more-container .crt-load-more:focus {
  outline: none !important
}

.btn {
  display: inline-block;
  overflow: visible;
  text-transform: none;
  font-family: Gibson, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  width: 100%;
  margin-top: .25rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  border: 1px solid #e85494;
  border-radius: 20em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 13px;
  font-size: .8125rem;
  line-height: 14px;
  line-height: .875rem;
  letter-spacing: .05em;
  color: #e85494;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -webkit-transition: .3s cubic-bezier(.86, 0, .07, 1);
  transition: .3s cubic-bezier(.86, 0, .07, 1);
  cursor: pointer
}

@media (min-width:768px) {
  .btn {
    display: inline-block;
    width: auto;
    padding: 1.4375rem 3rem;
    font-size: 14px;
    font-size: .875rem;
    line-height: 16px;
    line-height: 1rem
  }
}

.btn.btn-small {
  padding: 1rem 1.75rem
}

.btn:hover {
  color: #e85494;
  background-color: #fce1e5;
  border-color: #fce1e5
}

.btn.full {
  width: 100%
}

.btn.btn-white {
  border: none;
  background: #fff;
  color: #e85494
}

.btn.btn-white:hover {
  background-color: #e85494;
  -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, .15);
  box-shadow: 0 7px 22px rgba(0, 0, 0, .15);
  color: #fff
}

.btn.btn-pink {
  border: none !important;
  background: #e85494 !important;
  color: #fff !important;
  font-weight: 800 !important
}

.btn.btn-pink:hover {
  background-color: #f5a1bb !important;
  -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, .15) !important;
  box-shadow: 0 7px 22px rgba(0, 0, 0, .15) !important;
  color: #fff !important
}

.btn.btn-light {
  border: none !important;
  background: #f5a1bb !important;
  color: #fff !important;
  font-weight: 800 !important
}

.btn.btn-light:hover {
  background-color: #e85494 !important;
  -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, .15) !important;
  box-shadow: 0 7px 22px rgba(0, 0, 0, .15) !important;
  color: #fff !important
}

.btn.btn-disabled {
  border: none !important;
  background: #616366 !important;
  color: #fff !important;
  font-weight: 800 !important;
  cursor: not-allowed
}

.btn.btn-disabled:hover {
  background-color: #616366 !important
}

.sliders {
  visibility: hidden
}

.sliders .slider {
  display: flex;
  flex-direction: column-reverse;
  background: none no-repeat scroll top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: auto;
  width: 100%
}

@media (min-width:992px) {
  .sliders .slider {
    flex-direction: row;
    height: 480px
  }
}

.sliders .slider>* {
  flex: 0 0 auto;
  width: 100%
}

.sliders .slider .slider_image {
  width: 100%
}

.sliders .slider .slider_image .image_wrap img {
  width: 100%;
  height: 100%
}

@media (min-width:992px) {
  .sliders .slider .slider_image {
    flex: 1 1 auto
  }
  .sliders .slider .slider_image .image_wrap {
    display: none
  }
}

.sliders .slider .slider_content {
  background-color: rgba(245, 161, 187, .9);
  padding: 15px 20px;
  flex: 1 1 auto;
  width: 100%
}

.sliders .slider .slider_content.dark {
  background-color: rgba(237, 16, 137, .6)
}

@media (min-width:992px) {
  .sliders .slider .slider_content {
    flex: 1 1 auto;
    margin-right: 80px;
    margin-left: 80px;
    min-width: 400px;
    max-width: 400px;
    min-height: 305px;
    display: flex;
    background-color: rgba(245, 161, 187, .9)
  }
}

.sliders .slider .slider_content .slider_textbox {
  width: 100%;
  border-bottom: none !important
}

@media (min-width:576px) {
  .sliders .slider .slider_content .slider_textbox {
    display: flex;
    flex-direction: row;
    padding: 5px 30px
  }
  .sliders .slider .slider_content .slider_textbox>* {
    flex: 1 1 50%
  }
}

@media (min-width:992px) {
  .sliders .slider .slider_content .slider_textbox {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px
  }
  .sliders .slider .slider_content .slider_textbox>* {
    flex: 0 0 auto
  }
}

.sliders .slider .slider_content .slider_textbox:hover {
  border-bottom: none !important
}

.sliders .slider .slider_content .slider_textbox:hover .cta-link,
.sliders .slider .slider_content .slider_textbox:hover h1 {
  color: #e85494
}

.sliders .slider .slider_content .slider_textbox h1 {
  margin: 0 0 .5rem;
  white-space: normal;
  border-bottom: 0;
  text-transform: none;
  line-height: 1;
  font-size: 1.8em;
  color: #fff;
  width: 100%;
  padding: 0 1rem 0 0
}

@media (min-width:992px) {
  .sliders .slider .slider_content .slider_textbox h1 {
    padding-right: 0;
    margin-bottom: 1.25rem
  }
}

.sliders .slider .slider_content .slider_textbox .details {
  width: 100%
}

@media (min-width:576px) {
  .sliders .slider .slider_content .slider_textbox .details {
    border-left: 2px solid #fff;
    padding-left: 2rem
  }
}

@media (min-width:992px) {
  .sliders .slider .slider_content .slider_textbox .details {
    border-left: 0;
    padding-left: 0
  }
}

.sliders .slider .slider_content .slider_textbox .desc {
  display: none;
  color: #fff;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 1rem
}

.sliders .slider .slider_content .slider_textbox .desc * {
  font-size: inherit
}

@media (min-width:576px) {
  .sliders .slider .slider_content .slider_textbox .desc {
    display: block
  }
}

.sliders .slider .slider_content .slider_textbox .cta-link {
  color: #fff;
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  line-height: 21px;
  font-weight: 800
}

.sliders .slider .slider_content .slider_textbox .cta-link:after {
  display: inline-block;
  padding-left: 10px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  font-size: 14px;
  content: "\f054"
}

.sliders .slider .slider_content .slider_textbox .cta-link:hover {
  color: #e85494
}

@media (min-width:576px) {
  .sliders .slider .slider_content .slider_textbox .cta-link {
    font-size: 18px
  }
}

.slick-track {
  display: flex
}

.slick-track .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center
}

.slick-arrow {
  flex: 0 0 auto;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 0;
  z-index: 999;
  line-height: 4rem
}

.slick-arrow:before {
  font-size: 4rem;
  line-height: 4rem;
  z-index: 98;
  color: hsla(0, 0%, 100%, .5333333333333333);
  font-family: Font Awesome\ 5 Free;
  font-weight: 900
}

.slick-arrow.slick-prev {
  left: 15px;
  height: 4rem;
  width: 4rem
}

.slick-arrow.slick-prev:before {
  content: "\f053"
}

.slick-arrow.slick-next {
  right: 15px;
  height: 4rem;
  width: 4rem
}

.slick-arrow.slick-next:before {
  content: "\f054"
}

.slick-dots {
  bottom: 15px !important;
  z-index: 2
}

.slick-dots>li {
  display: inline-block
}

.slick-dots>li:only-child {
  display: none
}

.slick-dots li button:before {
  color: hsla(0, 0%, 100%, .5333333333333333);
  opacity: 1;
  font-size: 16px;
  font-weight: 900
}

.slick-dots li.slick-active button:before {
  color: rgba(245, 161, 187, .9);
  font-size: 16px;
  font-weight: 900
}

.fadeslide-right-hide {
  -webkit-animation: fadeslide-right-hide .15s ease;
  -moz-animation: fadeslide-right-hide .15s ease;
  -ms-animation: fadeslide-right-hide .15s ease;
  -o-animation: fadeslide-right-hide .15s ease;
  animation: fadeslide-right-hide .15s ease;
  opacity: 0;
  visibility: hidden;
  margin-left: 40px;
  margin-right: -40px
}

@-webkit-keyframes fadeslide-right-hide {
  0% {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
  to {
    margin-left: 40px;
    margin-right: -40px;
    opacity: 0;
    visibility: hidden
  }
}

@keyframes fadeslide-right-hide {
  0% {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
  to {
    margin-left: 40px;
    margin-right: -40px;
    opacity: 0;
    visibility: hidden
  }
}

.fadeslide-right-show {
  -webkit-animation: fadeslide-right-show .15s ease;
  -moz-animation: fadeslide-right-show .15s ease;
  -ms-animation: fadeslide-right-show .15s ease;
  -o-animation: fadeslide-right-show .15s ease;
  animation: fadeslide-right-show .15s ease;
  opacity: 1;
  visibility: visible;
  margin-left: 0;
  margin-right: 0
}

@-webkit-keyframes fadeslide-right-show {
  0% {
    margin-left: 40px;
    margin-right: -40px;
    opacity: 0;
    visibility: hidden
  }
  to {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
}

@keyframes fadeslide-right-show {
  0% {
    margin-left: 40px;
    margin-right: -40px;
    opacity: 0;
    visibility: hidden
  }
  to {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
}

.fadeslide-left-hide {
  -webkit-animation: fadeslide-left-hide .15s ease;
  -moz-animation: fadeslide-left-hide .15s ease;
  -ms-animation: fadeslide-left-hide .15s ease;
  -o-animation: fadeslide-left-hide .15s ease;
  animation: fadeslide-left-hide .15s ease;
  opacity: 0;
  visibility: hidden;
  margin-left: -40px;
  margin-right: 40px
}

@-webkit-keyframes fadeslide-left-hide {
  0% {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
  to {
    margin-left: -40px;
    margin-right: 40px;
    opacity: 0;
    visibility: hidden
  }
}

@keyframes fadeslide-left-hide {
  0% {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
  to {
    margin-left: -40px;
    margin-right: 40px;
    opacity: 0;
    visibility: hidden
  }
}

.fadeslide-left-show {
  -webkit-animation: fadeslide-left-show .15s ease;
  -moz-animation: fadeslide-left-show .15s ease;
  -ms-animation: fadeslide-left-show .15s ease;
  -o-animation: fadeslide-left-show .15s ease;
  animation: fadeslide-left-show .15s ease;
  opacity: 1;
  visibility: visible;
  margin-left: 0;
  margin-right: 0
}

@-webkit-keyframes fadeslide-left-show {
  0% {
    margin-left: -40px;
    margin-right: 40px;
    opacity: 0;
    visibility: hidden
  }
  to {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
}

@keyframes fadeslide-left-show {
  0% {
    margin-left: -40px;
    margin-right: 40px;
    opacity: 0;
    visibility: hidden
  }
  to {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    visibility: visible
  }
}

.fadeslide-top-hide {
  -webkit-animation: fadeslide-top-hide .15s ease;
  -moz-animation: fadeslide-top-hide .15s ease;
  -ms-animation: fadeslide-top-hide .15s ease;
  -o-animation: fadeslide-top-hide .15s ease;
  animation: fadeslide-top-hide .15s ease;
  opacity: 0;
  visibility: hidden;
  margin-top: -40px;
  height: 0
}

@-webkit-keyframes fadeslide-top-hide {
  0% {
    margin-top: 0;
    opacity: 1;
    visibility: visible
  }
  to {
    margin-top: -40px;
    opacity: 0;
    visibility: hidden
  }
}

@keyframes fadeslide-top-hide {
  0% {
    margin-top: 0;
    opacity: 1;
    visibility: visible
  }
  to {
    margin-top: -40px;
    opacity: 0;
    visibility: hidden
  }
}

.fadeslide-top-show {
  -webkit-animation: fadeslide-top-show .15s ease;
  -moz-animation: fadeslide-top-show .15s ease;
  -ms-animation: fadeslide-top-show .15s ease;
  -o-animation: fadeslide-top-show .15s ease;
  animation: fadeslide-top-show .15s ease;
  opacity: 1;
  visibility: visible;
  height: auto;
  margin-top: 0
}

@-webkit-keyframes fadeslide-top-show {
  0% {
    margin-top: -40px;
    opacity: 0;
    visibility: hidden
  }
  to {
    margin-top: 0;
    opacity: 1;
    visibility: visible
  }
}

@keyframes fadeslide-top-show {
  0% {
    margin-top: -40px;
    opacity: 0;
    visibility: hidden
  }
  to {
    margin-top: 0;
    opacity: 1;
    visibility: visible
  }
}

label.option {
  display: flex;
  line-height: 2rem;
  font-size: 1rem;
  margin: .25rem 0;
  cursor: pointer
}

label.option>input[type=checkbox],
label.option input[type=radio] {
  display: none
}

label.option>input[type=checkbox]:checked+.option-box,
label.option input[type=radio]:checked+.option-box {
  background: #f5a1bb;
  border-color: #f5a1bb
}

label.option>input[type=checkbox]:checked+.option-box i,
label.option input[type=radio]:checked+.option-box i {
  color: #fff;
  transform: translate(-50%, -50%) scale(1);
  transition-duration: .2s;
  opacity: 1
}

label.option>.option-text+.option-box {
  margin-left: .5rem
}

label.option>input[type=checkbox]+.option-box {
  border-radius: .25rem
}

label.option>input[type=radio]+.option-box {
  border-radius: 2rem
}

label.option>.option-box {
  display: inline-block;
  background: #fff;
  border: 1px solid silver;
  position: relative;
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;
  transition: background .3s ease
}

label.option>.option-box.hover {
  background: rgba(0, 0, 0, .5)
}

label.option>.option-box>i {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  display: inline-block;
  opacity: 0;
  pointer-events: none;
  transition: all .14s ease-in-out;
  transform: translate(-50%, -50%) scale(1)
}

label.option>.option-box>i.fa-spinner {
  top: 4px;
  left: 4px
}

.radio-group {
  display: flex;
  flex-direction: column;
  align-items: center
}

.radio-group.inline-2 {
  flex-direction: row
}

.radio-group.inline-2 .radio-options {
  display: flex;
  flex-direction: column
}

@media (min-width:768px) {
  .radio-group.inline-2 .radio-options {
    flex-direction: row
  }
}

.radio-group.inline-2 .radio-options .option {
  width: auto;
  padding-left: 1rem
}

.radio-group.inline-2 .radio-options.bool .option {
  max-width: 77px;
  min-width: 77px
}

.radio-group.inline {
  align-items: flex-start
}

@media (min-width:768px) {
  .radio-group.inline {
    flex-direction: row;
    align-items: center
  }
}

.radio-group.inline .radio-options {
  display: flex;
  flex-direction: row
}

.radio-group.inline .radio-options .option {
  width: auto;
  padding-left: 1rem
}

.radio-group.inline .radio-options.bool .option {
  max-width: 77px;
  min-width: 77px
}

.radio-group>label.desc {
  flex: 1 1
}

.radio-group>h3.desc {
  margin: .25rem 0 1rem;
  line-height: 2rem
}

.radio-group .option {
  margin-bottom: 1rem;
  width: 100%
}

.radio-group .option.divide {
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid #eaeaea
}

.radio-group .option.divide:last-child {
  border-bottom: none
}

.form-break {
  margin: 0 0 2rem;
  padding-top: 1rem;
  border-bottom: 1px solid #f5a1bb
}

label {
  display: block;
  color: #999;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0;
  font-weight: 400
}

label.label-checkbox,
label.label-radio {
  display: flex;
  flex-direction: row;
  justify-content: center
}

label.label-checkbox span,
label.label-radio span {
  flex: 1 1 auto;
  display: inline-block;
  margin-left: 1rem;
  font-size: 1rem
}

label.label-checkbox input[type=checkbox],
label.label-radio input[type=checkbox] {
  flex: 0 0 auto;
  margin-top: 4px
}

.divider {
  height: 1px;
  font-size: 1px;
  border-top: 1px solid #fce1e5;
  margin: 1rem 0 1.5rem
}

.inline-field {
  margin-left: 38px
}

.inline-field .input-group-text {
  padding: 8px 16px
}

.inline-field .form-control {
  max-width: 200px
}

.form-control::placeholder {
  color: #999 !important;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #999 !important
}

.form-control::-ms-input-placeholder {
  color: #999 !important
}

.form-submit.form-payment-submit {
  height: 40px;
  border-radius: 4px;
  width: 100%;
  padding: 0 !important
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: .5rem 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #616366;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  border-radius: 3px
}

.StripeElement--focus {
  border-color: #f5a1bb !important;
  outline: 0 !important;
  box-shadow: none !important
}

.StripeElement--invalid {
  border-color: #e85494
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important
}

#card-errors {
  color: #db6496 !important;
  font-weight: 700;
  display: block;
  font-size: .95rem;
  line-height: 1.6;
  padding: .5rem;
  background-color: #fdf1f5;
  display: none
}

#payment-request-button-ex {
  box-sizing: border-box;
  height: 40px;
  padding: 0 !important;
  margin-bottom: 1rem;
  background-color: #fff;
  border: none;
  border-radius: 0 !important;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

#payment-service-option {
  display: none
}

#payment-request-button {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  display: inline-block;
  padding: 0 !important;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 4px !important;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

#payment-request-button span {
  display: none;
  max-height: 40px
}

#payment-request-button span img {
  max-height: 100%;
  max-width: 100%
}

#payment-request-button:hover {
  background-color: #efefef
}

.story-wrap .story-image {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem
}

.story-wrap .story-image img {
  max-width: 100%
}

.story-wrap .story-content {
  margin: 0 auto;
  max-width: 520px
}

.story-wrap .story-more {
  text-align: center
}

.stories-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.stories-list .story {
  flex: 0 0 100%;
  padding: 1rem;
  height: 100%
}

.stories-list .story a {
  display: block;
  text-decoration: none;
  background: #fbe1e5;
  padding: 0;
  border: 0;
  position: relative
}

.stories-list .story a img {
  width: 100%;
  height: 100%
}

.stories-list .story a:hover {
  text-decoration: none !important;
  border-bottom: 1px solid transparent !important;
  opacity: .9
}

.stories-list .story a h3 {
  position: absolute;
  bottom: 0;
  padding: .8rem;
  margin: 0;
  font-size: 1.2rem;
  color: #fff !important;
  background-color: rgba(0, 0, 0, .4);
  text-align: left;
  width: 100%
}

.stories-list .story a .story-more {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.2rem
}

.stories-list .story a .story-more h2 {
  flex: 0 0 auto;
  font-size: 2.2rem;
  line-height: normal;
  color: #e85494;
  text-align: left;
  padding: 0;
  margin: 0
}

.stories-list .story a .story-more p {
  display: block;
  padding-top: 1.2rem;
  flex: 1 1 auto
}

.stories-list .story a .story-more .intouch {
  flex: 0 0 auto;
  border-top: 1px solid #e85494;
  padding-top: 1.2rem;
  display: block;
  bottom: 0;
  width: 100%
}

@media (min-width:576px) {
  .stories-list .story a .story-more {
    padding: .8rem
  }
  .stories-list .story a .story-more h2 {
    font-size: 1.2rem
  }
  .stories-list .story a .story-more .intouch,
  .stories-list .story a .story-more p {
    padding-top: .6rem
  }
}

@media (min-width:992px) {
  .stories-list .story a .story-more {
    padding: .8rem
  }
  .stories-list .story a .story-more h2 {
    font-size: 2.2rem
  }
}

@media (min-width:576px) {
  .stories-list .story {
    flex: 0 0 50%
  }
}

@media (min-width:768px) {
  .stories-list .story {
    flex: 0 0 33%
  }
}

.stats-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #fce1e5;
  padding: 1rem
}

.stats-card .details .count {
  color: #f5a1bb;
  font-size: 26px;
  line-height: 1;
  font-weight: 700;
  padding-top: .75rem
}

@media (min-width:768px) {
  .stats-card .details .count {
    font-size: 32px
  }
}

.stats-card .details .label {
  font-weight: 700;
  color: #616366
}

.stats-card .icon {
  height: 50px;
  margin: auto 0 auto auto
}

.stats-card .icon img {
  width: 100%;
  height: 100%
}

.stats-block {
  display: flex;
  flex-direction: column
}

@media (min-width:768px) {
  .stats-block {
    flex-direction: row
  }
}

.stats-block .stats-panel {
  flex: 1 1 50%;
  padding: 0 .5rem
}

.stat {
  background-color: #f9f9f9;
  padding: 1rem
}

.stat,
.stat .count-wrap {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px
}

.stat .count-wrap {
  background: #e9e9e9;
  padding: 5px
}

.stat .count-wrap>* {
  padding: 23px 0;
  background: #fff;
  text-align: center;
  color: #f5a1bb;
  font-size: 26px;
  line-height: 1;
  font-weight: 700
}

.stat .image,
.stat .label {
  text-align: center
}

.stat .label {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 14px;
  color: #616366
}

.info-block {
  background-color: #f9f9f9;
  padding: 1rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 1rem
}

.info-block .info-panel {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #e85494;
  margin-bottom: 30px
}

.actions-block {
  padding: 40px 0;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center
}

.actions-block .action-panel {
  max-width: 296px;
  flex: 0 0 auto;
  margin: 0 0 2rem;
  padding: 1rem
}

.actions-block .action-panel .action .upper {
  height: 30%;
  min-height: 155px;
  background-color: #f5a1bb;
  position: relative
}

.actions-block .action-panel .action .upper .step-icon {
  margin: auto;
  max-height: 154px;
  max-width: 154px;
  border-radius: 50%;
  padding-top: 38px
}

.actions-block .action-panel .action .upper .step-icon img {
  padding: 1rem;
  background-color: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%
}

.actions-block .action-panel .action .lower {
  height: 70%;
  min-height: 250px;
  background-color: #e85494
}

.actions-block .action-panel .action .lower>* {
  text-align: center
}

.actions-block .action-panel .action .lower .desc {
  padding: 65px 20px 0
}

.actions-block .action-panel .action .lower .desc h3 {
  color: #fff
}

.actions-block .action-panel .action .lower .desc p {
  padding: 10px 5px;
  font-size: 14px;
  color: #fff;
  display: block;
  min-height: 86px
}

.actions-block .action-panel .action .lower .cta {
  padding: 0 10px
}

.content-body-wrap iframe {
  width: 100%
}

.content-body-wrap h2 {
  font-size: 27px;
  color: #e85494
}

.content-home-wrap .intro {
  padding: 20px;
  margin-bottom: 0px
}

@media (min-width:768px) {
  .content-home-wrap .intro {
    padding: 40px 80px
  }
}

@media (min-width:992px) {
  .content-home-wrap .intro {
    padding: 6rem 120px
  }
  .content-home-wrap .intro .content-left {
    margin-right: 1.5rem
  }
  .content-home-wrap .intro .content-center {
    text-align: center;
    max-width: 860px;
    margin: 0 auto
  }
}

.content-home-wrap h1 {
  padding-bottom: 0
}

.call-actions {
  display: flex;
  flex-direction: column
}

@media (min-width:768px) {
  .call-actions {
    flex-direction: row
  }
}

.call-actions .action-wrap {
  padding: 0 .75rem .75rem;
  flex: 0 0 33%;
}

@media (min-width:768px) {
  .call-actions .action-wrap {
    flex: 0 0 33%;
  }
}

.call-actions .action-wrap:nth-child(2n) .action {
  background-color: #f5a1bb
}

.call-actions .action-wrap:nth-child(2n) .action:hover .link span {
  color: #e85494
}

.call-actions .action-wrap .action {
  background-color: #e85494;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: none !important
}

.call-actions .action-wrap .action:hover {
  border-bottom: none !important
}

.call-actions .action-wrap .action:hover .link span {
  color: #f5a1bb
}

.call-actions .action-wrap .action .image {
  flex: 0 0 auto;
  max-width: 100%;
  max-height: 100%
}

.call-actions .action-wrap .action .image img {
  max-width: 100%;
  max-height: 100%
}

.call-actions .action-wrap .action .content {
  flex: 1 1 auto;
  margin: 1rem;
  color: #fff
}

.call-actions .action-wrap .action .content h2 {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: .75rem;
  color: #fff;
  min-height: 68px
}

@media (min-width:768px) {
  .call-actions .action-wrap .action .content h2 {
    font-size: 24px
  }
}

.call-actions .action-wrap .action .link {
  flex: 0 0 auto;
  margin: 1rem;
  border-top: 1px solid #fff
}

.call-actions .action-wrap .action .link span {
  padding: .75rem 0;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 27px;
  font-weight: 600;
  display: block;
  text-decoration: none;
  border-bottom: none
}

@media (min-width:768px) {
  .call-actions .action-wrap .action .link span {
    font-size: 24px
  }
  .call-actions .action-wrap .action .link span i {
    font-size: 20px
  }
}

.image-set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%
}

.image-set .image {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  text-align: center
}

.image-set .image img {
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 1rem
}

@media (min-width:768px) {
  .image-set {
    flex-direction: row
  }
  .image-set.set-3 .image {
    flex: 1 1 33%
  }
}

.panel-3-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 3rem
}

.panel-3-list .panel-wrap {
  flex: 1 1 100%
}

@media (min-width:768px) {
  .panel-3-list .panel-wrap {
    flex: 0 0 33%
  }
  .panel-3-list .panel-wrap .panel {
    margin-left: 0;
    margin-right: 1rem
  }
  .panel-3-list .panel-wrap:nth-child(2n) .panel {
    margin-left: .5rem;
    margin-right: .5rem
  }
  .panel-3-list .panel-wrap:nth-child(3n) .panel {
    margin-left: 1rem;
    margin-right: 0
  }
}

@media (min-width:992px) {
  .panel-3-list .panel-wrap .panel {
    margin-left: 0;
    margin-right: 2rem
  }
  .panel-3-list .panel-wrap:nth-child(2n) .panel {
    margin-left: 1rem;
    margin-right: 1rem
  }
  .panel-3-list .panel-wrap:nth-child(3n) .panel {
    margin-left: 2rem;
    margin-right: 0
  }
}

.panel-3-list .panel-wrap .panel {
  text-align: center
}

.panel-3-list .panel-wrap .panel h3 {
  font-size: 20px
}

.panel-3-list .panel-wrap .panel .donation-spec {
  text-align: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  width: 100%;
  height: auto;
  max-width: 180px;
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto
}

.panel-3-list .panel-wrap .panel .donation-spec:first-child {
  margin-top: 1rem
}

.panel-3-list .panel-wrap .panel .donation-spec:after {
  content: "";
  padding-bottom: 100%;
  display: block;
  z-index: 1000000
}

.panel-3-list .panel-wrap .panel .donation-spec p {
  display: block;
  height: 100%;
  flex: 1 1 100%;
  text-align: center;
  font-weight: 700;
  font-size: 55px;
  color: #fff
}

.panel-3-list .panel-wrap .panel.icon {
  padding-top: 2rem
}

.panel-3-list .panel-wrap .panel.icon:first-child {
  padding-top: 0
}

.panel-3-list .panel-wrap .panel.icon img {
  max-width: 120px;
  margin: 0 auto 2rem
}

.project-spotlight {
  display: flex;
  flex-direction: column
}

.project-spotlight p {
  margin-bottom: 1.25rem
}

.project-spotlight.sm-flex-rev {
  flex-direction: column-reverse
}

@media (min-width:768px) {
  .project-spotlight {
    flex-direction: row;
    margin-bottom: 3rem;
    padding: 1rem 3rem
  }
  .project-spotlight.sm-flex-rev {
    flex-direction: row
  }
}

.project-spotlight .spotlight-content {
  flex: 0 0 50%
}

.project-spotlight .spotlight-content h5 {
  text-align: left;
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  color: #e85494
}

.project-spotlight .spotlight-asset {
  flex: 0 0 50%;
  text-align: center
}

.project-spotlight .spotlight-asset img {
  width: 100%;
  height: auto;
  max-width: 200px;
  max-height: 200px
}

@media (min-width:768px) {
  .project-spotlight .spotlight-asset {
    text-align: right;
    padding-left: 1.5rem
  }
  .project-spotlight .spotlight-asset img {
    max-width: 380px;
    max-height: 380px
  }
}

.project-spotlight .spotlight-asset.rounded img {
  border-radius: 50%
}

.panel-content {
  padding: 1rem
}

.panel-content h2 {
  font-size: 27px;
  color: #e85494
}

.panel-content iframe {
  width: 100%
}

.panel-content.panel-light {
  background-color: #fdf1f5
}

.panel-content.panel-pink {
  background-color: #f5a1bb;
  color: #fff
}

.panel-content.panel-pink h2,
.panel-content.panel-pink h3,
.panel-content.panel-pink h5 {
  color: #fff !important
}

.panel-content.panel-dark {
  background-color: #e85494;
  color: #fff
}

.panel-content.panel-dark h2,
.panel-content.panel-dark h3,
.panel-content.panel-dark h5 {
  color: #fff !important
}

.panel-content.notched {
  position: relative
}

.panel-content.notched:before {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -24px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 24px 0;
  z-index: 999;
  content: ""
}

.panel-content.notched.notched-white:before,
.panel-content.notched:before {
  border-color: #fff transparent transparent
}

.panel-content.notched.notched-light:before {
  border-color: #fdf1f5 transparent transparent
}

.panel-content.notched.notched-pink:before {
  border-color: #f5a1bb transparent transparent
}

.panel-content.notched.notched-dark:before {
  border-color: #e85494 transparent transparent
}

.partners {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem 0
}

@media (min-width:768px) {
  .partners {
    flex-wrap: nowrap
  }
}

.partners .partner-panel {
  flex: 1 1 50%;
  padding: 1rem .25rem
}

@media (min-width:768px) {
  .partners .partner-panel {
    flex: 1 1 auto;
    padding: 1rem
  }
}

.partners .partner-panel .partner {
  display: flex;
  flex-direction: column;
  height: 100%
}

.partners .partner-panel .partner .upper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.partners .partner-panel .partner .upper .logo {
  text-align: center;
  max-width: 60px
}

@media (min-width:768px) {
  .partners .partner-panel .partner .upper .logo {
    max-width: 100px
  }
}

.partners .partner-panel .partner .upper .logo img {
  max-width: 100%;
  max-height: 100%
}

.partners .partner-panel .partner .label {
  flex: 0 0 auto;
  margin-top: 1rem;
  text-align: center;
  font-size: .75rem
}

@media (min-width:768px) {
  .partners .partner-panel .partner .label {
    font-size: 1rem
  }
}

.partners .partner-panel.major .partner .upper .logo {
  max-width: 120px
}

@media (min-width:768px) {
  .partners .partner-panel.major .partner .upper .logo {
    max-width: 180px
  }
}

.sponsors-list .sponsor {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #fce1e5
}

.sponsors-list .sponsor:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0
}

.sponsors-list .sponsor .logo {
  height: 100%;
  width: 100%;
  text-align: center
}

.sponsors-list .sponsor .logo img {
  max-width: 200px;
  height: 100%;
  width: 100%;
  margin-bottom: 20px
}

.sponsors-list .sponsor .logo a {
  text-decoration: none;
  border-bottom: none !important
}

.sponsors-list .sponsor .logo a:hover {
  text-decoration: none;
  border-bottom: none
}

.sponsors-list .sponsor .desc h2 {
  text-align: center
}

.sponsors-list .sponsor .desc h2 a {
  border-bottom: none !important;
  font-weight: 700 !important
}

@media (min-width:768px) {
  .sponsors-list .sponsor {
    flex-direction: row
  }
  .sponsors-list .sponsor .logo {
    max-width: 200px;
    flex: 0 0 auto;
    padding-right: 2rem
  }
  .sponsors-list .sponsor .desc h2 {
    text-align: left
  }
}

.resource-groups {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.resource-groups .resource-group-panel {
  flex: 0 0 100%
}

@media (min-width:576px) {
  .resource-groups .resource-group-panel {
    flex: 0 0 50%
  }
  .resource-groups .resource-group-panel .resource-group {
    margin-left: 0;
    margin-right: 1rem
  }
  .resource-groups .resource-group-panel .resource-group h2 {
    text-align: left
  }
  .resource-groups .resource-group-panel:nth-child(2n) .hack {
    margin-left: 1rem;
    margin-right: 0
  }
}

@media (min-width:768px) {
  .resource-groups .resource-group-panel {
    flex: 0 0 50%
  }
  .resource-groups .resource-group-panel .resource-group {
    margin-left: 0;
    margin-right: 2rem
  }
  .resource-groups .resource-group-panel:nth-child(2n) .hack {
    margin-left: 2rem;
    margin-right: 0
  }
}

.resource-groups .link {
  margin-top: .5rem;
  margin-bottom: 1rem
}

.resource-groups p {
  display: inline-block;
  min-height: 75px
}

.resource-groups h2 {
  border-top: 1px solid #f5a1bb;
  color: #e85494;
  padding-top: 1.5rem;
  padding-bottom: 0 !important;
  text-align: center
}

.resource-groups h2 img {
  width: 40px;
  height: 40px;
  margin: 0 .5em 0 0;
  display: inline-block;
  vertical-align: middle
}

.resource-groups ul {
  list-style: none;
  padding: 0;
  margin: 0 0 3rem
}

.resource-groups ul li {
  line-height: 1.4;
  font-size: 15px
}

.resource-groups ul li a {
  position: relative;
  display: inline-block;
  padding: 1rem 0 0 1.5rem;
  margin-bottom: .5rem;
  color: #4d4f53 !important
}

.resources-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.resources-list .resource {
  flex: 0 0 100%;
  padding: 1rem;
  height: 100%
}

.resources-list .resource a {
  display: block;
  border-bottom: none !important;
  text-decoration: none;
  background: #fff;
  padding: 0;
  border: 0
}

.resources-list .resource a:hover {
  text-decoration: none;
  border-bottom: none;
  opacity: .9
}

.resources-list .resource a img {
  width: 100%;
  height: 100%
}

.resources-list .resource a h3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #e85494;
  text-align: left
}

.resources-list .resource .no-link {
  display: block;
  padding: 0;
  border: 0
}

.resources-list .resource .no-link img {
  width: 100%;
  height: 100%
}

.resources-list .resource .no-link h3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  font-size: 1rem;
  color: #e85494;
  text-align: left
}

@media (min-width:576px) {
  .resources-list .resource {
    flex: 0 0 50%
  }
}

@media (min-width:768px) {
  .resources-list .resource {
    flex: 0 0 33%
  }
}

.prizes-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem
}

.prizes-list .prizes-wrap {
  flex: 1 1 100%
}

@media (min-width:768px) {
  .prizes-list .prizes-wrap {
    flex: 1 1 50%
  }
  .prizes-list .prizes-wrap .prize {
    margin-left: 0;
    margin-right: 1rem
  }
  .prizes-list .prizes-wrap:nth-child(2n) .prize {
    margin-left: 1rem;
    margin-right: 0
  }
}

@media (min-width:992px) {
  .prizes-list .prizes-wrap {
    flex: 0 0 50%
  }
  .prizes-list .prizes-wrap .prize {
    margin-left: 0;
    margin-right: 2rem
  }
  .prizes-list .prizes-wrap:nth-child(2n) .prize {
    margin-left: 2rem;
    margin-right: 0
  }
}

.prizes-list .prizes-wrap .prize {
  padding-bottom: 2rem
}

.prizes-list .prizes-wrap .prize h2 {
  border-top: 1px solid #f5a1bb;
  padding-top: 1.5rem;
  padding-bottom: 0 !important
}

.prizes-list .prizes-wrap .prize h2 img {
  width: 40px;
  height: 40px;
  margin: 0 .5em 0 0;
  display: inline-block;
  vertical-align: middle
}

#form-purchase .product-wrap {
  border-top: 1px solid #e5e5e5;
  margin: 20px 0
}

#form-purchase .product-item {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e5e5e5
}

#form-purchase .product-item-image {
  max-height: 100px;
  max-width: 100px
}

#form-purchase .product-item-image img {
  height: 100%;
  width: 100%;
  border-color: #fff;
  border-radius: 5px
}

#form-purchase .product-item-details {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 20px;
  flex: 1 1 auto
}

#form-purchase .product-item-name,
#form-purchase .product-item-price {
  color: #e85494;
  font-weight: 300;
  font-size: 1.2rem;
  margin-right: 20px
}

#form-purchase .product-item-quantity {
  flex: 0 0 75px;
  margin-right: 10px
}

#form-purchase .product_quantity {
  text-align: right
}

#form-purchase .product-delivery {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end
}

#form-purchase .product-delivery-desc {
  font-weight: 700;
  font-size: 1.2rem;
  margin-right: 20px
}

#form-purchase .product-delivery-amount {
  font-weight: 300;
  font-size: 1.2rem
}

#form-purchase .product-total {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end
}

#form-purchase .product-total-desc {
  font-weight: 700;
  font-size: 1.2rem;
  margin-right: 20px
}

#form-purchase .product-total-amount {
  font-weight: 300;
  font-size: 1.2rem
}

.d-none {
  display: none !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}