.fundraiser-panel .fundraiser-image-wrap a img {
  max-height: auto;
  height: 100%
}

#modalPostponed .modal-dialog {
  max-width: 960px
}

#modalPostponed .modal-content {
  border-radius: 0;
  background-color: #fdf1f5
}

#modalPostponed .modal-content .modal-header {
  border-bottom: 0;
  padding-bottom: 0
}

#modalPostponed .modal-content .modal-header .close {
  color: #e85494
}

#modalPostponed .modal-content .modal-body {
  padding: 15px 30px 30px
}

#modalPostponed .modal-content .modal-body p {
  margin-top: 30px
}

@media (min-width:768px) {
  #modalPostponed .modal-content .modal-body {
    padding: 15px 60px 60px
  }
  #modalPostponed .modal-content .modal-body p {
    margin-top: 60px
  }
}

#modalPostponed .modal-content .modal-icon {
  margin: 0 auto;
  max-height: 100px;
  max-width: 100px;
  text-align: center
}

#modalPostponed .modal-content .modal-icon img {
  width: 100%;
  height: 100%
}

#modalPostponed .modal-content .modal-title {
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0;
  color: #e85494
}

.sprites-prb.img-pulldown {
  width: 8px;
  height: 34px;
  background-position: -7px -39px
}

.sprites-prb.img-toaster {
  width: 90px;
  height: 86px;
  background-position: -40px 0;
  margin-top: 17px
}

.sprites-prb.img-plunger {
  width: 71px;
  height: 100px;
  background-position: -130px 0;
  margin-left: 14px;
  margin-top: 15px
}

.content-body-wrap iframe {
  width: 100%
}

.content-body-wrap h2 {
  font-size: 27px;
  color: #e85494
}

.content-body-wrap.panel-light {
  background-color: #fdf1f5
}

.content-body-wrap.panel-pink {
  background-color: #f5a1bb;
  color: #fff
}

.content-body-wrap.panel-pink h2,
.content-body-wrap.panel-pink h3,
.content-body-wrap.panel-pink h5 {
  color: #fff !important
}

.content-body-wrap.panel-dark {
  background-color: #e85494;
  color: #fff
}

.content-body-wrap.panel-dark h2,
.content-body-wrap.panel-dark h3,
.content-body-wrap.panel-dark h5 {
  color: #fff !important
}

.content-body-wrap.notched {
  position: relative
}

.content-body-wrap.notched:before {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -12px;
  width: 0;
  height: 0;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 24px 24px 0;
  z-index: 999;
  content: ""
}

.content-body-wrap .custom-counter {
  margin: 1rem -1rem;
  padding: 1rem;
  background-color: rgba(97, 99, 102, .08);
  list-style-type: none
}

@media (min-width:768px) {
  .content-body-wrap .custom-counter {
    margin: 1.5rem 0;
    padding: 3rem
  }
}

.content-body-wrap .custom-counter li {
  counter-increment: step-counter;
  margin-bottom: 10px;
  position: relative;
  padding-left: 3.4em;
  padding-bottom: 1.5rem;
  line-height: 1.4;
  font-size: 15px
}

.content-body-wrap .custom-counter li:before {
  content: counter(step-counter);
  margin-right: .63em;
  background: #f5a1bb;
  font-weight: 700;
  color: #fff;
  border-radius: 20em;
  width: 2.4em;
  height: 2.4em;
  line-height: 2.4em;
  font-size: .8em;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  text-align: center
}

.content-body-wrap .product-item-image:hover {
  cursor: pointer
}

.content-body-wrap .modalImage {
  display: none;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000;
  background-color: rgba(0, 0, 0, .4);
  flex-direction: row;
  justify-content: center;
  align-items: center
}

.content-body-wrap .modalImage .modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  max-width: 500px;
  border-radius: 5px
}

.content-body-wrap .modalImage .modal-content img {
  max-width: 100%;
  max-height: 100%
}

.content-body-wrap .modalImage .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: 700
}

.content-body-wrap .modalImage .close:focus,
.content-body-wrap .modalImage .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer
}

.content-body-wrap .logo-set {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.content-body-wrap .logo-set .logo {
  flex: 0 0 100%;
  padding: 1rem 0;
  height: 100%
}

@media (min-width:768px) {
  .content-body-wrap .logo-set .logo {
    flex: 0 0 50%;
    padding: 1rem 2rem
  }
  .content-body-wrap .logo-set .logo:first-child {
    padding-left: 0
  }
  .content-body-wrap .logo-set .logo:last-child {
    padding-right: 0
  }
}

.content-body-wrap .logo-set .logo img {
  width: 100%;
  height: 100%
}

.content-body-wrap .logo-set .logo h3 {
  margin-top: 1rem
}

.content-body-wrap .ideas-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.content-body-wrap .ideas-list .idea {
  flex: 0 0 100%;
  padding: 1rem;
  height: 100%
}

.content-body-wrap .ideas-list .idea .idea-wrap {
  display: block;
  text-decoration: none;
  background: #fbe1e5;
  padding: 0;
  border: 0
}

.content-body-wrap .ideas-list .idea .idea-wrap img {
  width: 100%;
  height: 100%
}

.content-body-wrap .ideas-list .idea .idea-wrap h3 {
  padding: 1.2rem;
  margin: 0;
  font-size: 1.2rem;
  color: #e85494;
  text-align: center
}

.content-body-wrap .ideas-list .idea .idea-wrap .desc {
  padding: 0 1.2rem 1.2rem
}

@media (min-width:576px) {
  .content-body-wrap .ideas-list .idea {
    flex: 0 0 33%
  }
}

.content-body-wrap .recipes-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.content-body-wrap .recipes-list .recipe {
  flex: 0 0 100%;
  padding: 1rem;
  height: 100%
}

.content-body-wrap .recipes-list .recipe a {
  display: block;
  text-decoration: none;
  background: #fbe1e5;
  padding: 0;
  border: 0
}

.content-body-wrap .recipes-list .recipe a:hover {
  text-decoration: none;
  border-bottom: none;
  opacity: .9
}

.content-body-wrap .recipes-list .recipe a img {
  width: 100%;
  height: 100%
}

.content-body-wrap .recipes-list .recipe a h3 {
  padding: 1.2rem;
  margin: 0;
  font-size: 1.2rem;
  color: #e85494;
  min-height: 80px;
  text-align: center
}

@media (min-width:576px) {
  .content-body-wrap .recipes-list .recipe {
    flex: 0 0 33%
  }
}

.content-body-wrap .outlets-list-wrap .outlets-wrap {
  padding-top: 15px;
  border-top: 1px solid #f5a1bb;
  margin-top: 20px
}

.content-body-wrap .outlets-list-wrap .outlets-wrap:last-child {
  border-bottom: 1px solid #f5a1bb;
  padding-bottom: 20px;
  margin-bottom: 20px
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-header {
  display: flex;
  flex-direction: row
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-header h2 {
  flex: 1 1 auto;
  margin-bottom: 0;
  padding-bottom: 15px
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-header span {
  cursor: pointer;
  flex: 0 0 auto;
  color: #999;
  font-size: 1.1rem;
  margin-right: 1rem
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-header span i {
  color: #f5a1bb;
  margin-left: 1rem;
  font-size: 1.2rem
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-list li {
  padding: 10px 0;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  border-bottom: none
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-list li>* {
  flex: 0 0 33%;
  color: #aaadb2
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-list li>:first-child {
  color: #e85494
}

.content-body-wrap .outlets-list-wrap .outlets-wrap .outlet-list li:last-child {
  border-bottom: 1px solid #ddd
}

.content-body-wrap .hack-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.content-body-wrap .hack-list .hack-wrap {
  flex: 0 0 100%
}

@media (min-width:576px) {
  .content-body-wrap .hack-list .hack-wrap {
    flex: 0 0 50%
  }
  .content-body-wrap .hack-list .hack-wrap .hack {
    margin-left: 0;
    margin-right: 1rem
  }
  .content-body-wrap .hack-list .hack-wrap .hack h2 {
    text-align: left
  }
  .content-body-wrap .hack-list .hack-wrap:nth-child(2n) .hack {
    margin-left: 1rem;
    margin-right: 0
  }
}

@media (min-width:768px) {
  .content-body-wrap .hack-list .hack-wrap {
    flex: 0 0 50%
  }
  .content-body-wrap .hack-list .hack-wrap .hack {
    margin-left: 0;
    margin-right: 2rem
  }
  .content-body-wrap .hack-list .hack-wrap:nth-child(2n) .hack {
    margin-left: 2rem;
    margin-right: 0
  }
}

.content-body-wrap .hack-list .link {
  margin-top: .5rem;
  margin-bottom: 1rem
}

.content-body-wrap .hack-list p {
  display: inline-block;
  min-height: 75px
}

.content-body-wrap .hack-list h2 {
  border-top: 1px solid #f5a1bb;
  color: #e85494;
  padding-top: 1.5rem;
  padding-bottom: 0 !important;
  text-align: center
}

.content-body-wrap .hack-list h2 img {
  width: 40px;
  height: 40px;
  margin: 0 .5em 0 0;
  display: inline-block;
  vertical-align: middle
}

.content-body-wrap .hack-list ul {
  list-style: none;
  padding: 0;
  margin: 0 0 3rem
}

.content-body-wrap .hack-list ul li {
  line-height: 1.4;
  font-size: 15px
}

.content-body-wrap .hack-list ul li a {
  position: relative;
  display: inline-block;
  padding: 1rem 0 0 1.5rem;
  margin-bottom: .5rem;
  color: #4d4f53 !important
}

.content-body-wrap .tips-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.content-body-wrap .tips-list .tip-wrap {
  flex: 1 1 100%
}

@media (min-width:768px) {
  .content-body-wrap .tips-list .tip-wrap {
    flex: 1 1 50%
  }
  .content-body-wrap .tips-list .tip-wrap .tip {
    margin-left: 0;
    margin-right: 1rem
  }
  .content-body-wrap .tips-list .tip-wrap:nth-child(2n) .tip {
    margin-left: 1rem;
    margin-right: 0
  }
}

@media (min-width:992px) {
  .content-body-wrap .tips-list .tip-wrap {
    flex: 0 0 50%
  }
  .content-body-wrap .tips-list .tip-wrap .tip {
    margin-left: 0;
    margin-right: 2rem
  }
  .content-body-wrap .tips-list .tip-wrap:nth-child(2n) .tip {
    margin-left: 2rem;
    margin-right: 0
  }
}

.content-body-wrap .tips-list .tip-wrap .tip>p {
  margin: 0;
  padding: 0
}

.content-body-wrap .tips-list .tip-wrap .tip blockquote {
  box-shadow: none;
  border-radius: 0;
  border: 0;
  background: #fbe1e5;
  font-family: Merriweather, serif;
  font-style: italic;
  font-size: .925rem;
  padding: 1.5rem;
  margin: 0 0 2rem;
  color: #4d4f53 !important
}

.content-body-wrap .tips-list .tip-wrap .tip blockquote .quote {
  padding-top: 1.5rem;
  font-style: normal
}

#form-purchase-apron .product-wrap {
  border-top: 1px solid #e5e5e5;
  margin: 20px 0
}

#form-purchase-apron .product-item {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e5e5e5
}

#form-purchase-apron .product-item-image {
  max-height: 100px;
  max-width: 100px
}

#form-purchase-apron .product-item-image img {
  height: 100%;
  width: 100%;
  border-color: #fff;
  border-radius: 5px
}

#form-purchase-apron .product-item-details {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 20px;
  flex: 1 1 auto
}

#form-purchase-apron .product-item-name,
#form-purchase-apron .product-item-price {
  color: #e85494;
  font-weight: 300;
  font-size: 1.2rem;
  margin-right: 20px
}

#form-purchase-apron .product-item-quantity {
  flex: 0 0 75px;
  margin-right: 10px
}

#form-purchase-apron .product_quantity {
  text-align: right
}

#form-purchase-apron .product-delivery {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end
}

#form-purchase-apron .product-delivery-desc {
  font-weight: 700;
  font-size: 1.2rem;
  margin-right: 20px
}

#form-purchase-apron .product-delivery-amount {
  font-weight: 300;
  font-size: 1.2rem
}

#form-purchase-apron .product-total {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end
}

#form-purchase-apron .product-total-desc {
  font-weight: 700;
  font-size: 1.2rem;
  margin-right: 20px
}

#form-purchase-apron .product-total-amount {
  font-weight: 300;
  font-size: 1.2rem
}

.content-profile-wrap .hostkitoptions,
.content-profile-wrap .profile-full-details {
  display: none
}

.content-body-wrap .address-result {
  margin-top: 1rem;
  border: 1px solid pink;
  border-radius: 4px;
  padding: 15px;
  color: #616366;
  font-weight: 400;
  font-size: 1rem;
  width: 100%;
  display: none
}

.content-body-wrap .address-result .address-display {
  text-align: center
}

.content-body-wrap .address-result .address-display span {
  color: #e85494
}

.content-body-wrap .address-result .address-loading {
  text-align: center
}

.content-body-wrap .address-result .address-invalid {
  text-align: center;
  font-weight: 600;
  color: #e85494
}
